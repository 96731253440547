import type { AddressDto } from '@containex/portal-backend-dto';
import type { Address as MedusaAddress } from '@medusajs/medusa/dist/models/address';
import { isStringEmpty } from '@containex/common-utils';

// unsure if we should use a deep compare algorithm (e.g. lodash) for this altough addresses are not deeply nested
export function areAddressesEqual(addr1: AddressDto, addr2: AddressDto): boolean {
    return (
        addr1.firstName === addr2.firstName &&
        addr1.lastName === addr2.lastName &&
        addr1.address1 === addr2.address1 &&
        addr1.postalCode === addr2.postalCode &&
        addr1.city === addr2.city &&
        addr1.phone === addr2.phone
    );
}

// Medusa Address can be defined and even have an id, but no other relevant data
export function isMedusaAddressValid(addr: MedusaAddress): boolean {
    return addr.address_1 != null && addr.city != null && addr.postal_code != null;
}

export function isEnteredAddressEmpty(address: AddressDto): boolean {
    return (
        isStringEmpty(address.customerId) &&
        isStringEmpty(address.company) &&
        isStringEmpty(address.firstName) &&
        isStringEmpty(address.lastName) &&
        isStringEmpty(address.address1) &&
        isStringEmpty(address.address2) &&
        isStringEmpty(address.city) &&
        isStringEmpty(address.countryCode) &&
        isStringEmpty(address.province) &&
        isStringEmpty(address.phone)
    );
}
