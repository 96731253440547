<script setup lang="ts">
    import RadioButton from 'primevue/radiobutton';
    import InlineMessage from 'primevue/inlinemessage';
    import { computed, ref, useAttrs } from 'vue';

    const props = defineProps<{
        errorMessage?: string;
        infoMessage?: string;
        disabled?: boolean;
    }>();

    const attrs = useAttrs();

    const radio = ref<InstanceType<typeof RadioButton> | null>(null);

    const selected = computed(() => {
        if (radio.value == null) {
            return false;
        }

        return radio.value.$props.modelValue === radio.value.$props.value;
    });

    function select(): void {
        if (radio.value == null || attrs.disabled === true || props.disabled) {
            return;
        }

        radio.value.$emit('update:modelValue', radio.value.$props.value);
    }
</script>

<template>
    <div class="radio-card-container" :class="{ selected }">
        <label class="radio-card-content">
            <span class="radio">
                <RadioButton v-bind="$attrs" ref="radio" :disabled="disabled" />
            </span>
            <span class="header" @click="select">
                <InlineMessage v-if="infoMessage != null" class="inline-message" severity="info">{{
                    infoMessage
                }}</InlineMessage>
                <slot name="header"></slot>
            </span>
            <span class="content">
                <slot name="content"></slot>
            </span>
        </label>
        <small v-if="errorMessage != null" class="p-error">{{ errorMessage }}</small>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .radio-card-container {
        border: 1px solid var(--surface-border);
        cursor: pointer;

        &.selected {
            border-color: color-mix(in srgb, var(--color-secondary-blue) 40%, transparent);
        }
    }

    .inline-message {
        padding: 0;
        border: none;
        background: none;
    }

    // all this styling are here to make the radio button
    // centered on the header content
    .radio-card-content {
        cursor: pointer;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-areas:
            'radio header'
            'anon content';
        column-gap: main.$spacing-5;
        padding: main.$spacing-4 main.$spacing-5;

        > .radio {
            grid-area: radio;
            align-self: center;

            display: flex;
            align-items: center;
        }

        > .header {
            grid-area: header;
            align-self: center;
        }

        > .content {
            grid-area: content;
            grid-column: 1/-1;
        }
    }

    @include main.for-breakpoint-md {
        .radio-card-content {
            > .content {
                grid-area: content;
            }
        }
    }
</style>
