<script setup lang="ts">
    import { computed } from 'vue';
    import Card from 'primevue/card';
    import type { LineItem, LineItemGroup } from '@containex/portal-backend-api-client';
    import { differenceInWeeks, parseISO } from 'date-fns';
    import { useDateFormatter } from '@/composables/date-format';
    import { DepotType, TransportType } from '@containex/portal-backend-dto';
    import { useI18n } from 'vue-i18n';
    import { isDefined } from '@containex/common-utils';
    import ProductImage from '@/components/ProductImage.vue';
    import { medusaAddressToAddressDto } from '@containex/portal-medusa-mapper';
    import { useCartQuery } from '@/composables/cart';
    import { useCheckoutDeliveryOptionQuery } from '@/checkout/composables/checkout-delivery-option';
    import AddressDisplay from '@/components/AddressDisplay.vue';
    import { CheckoutDeliveryOption } from '@/checkout/model/checkout-delivery-option';
    import { lineItemsWithoutChildsSortedByDateAndContainersAndAdditionalProducts } from '@/util/line-items';

    const props = defineProps<{
        group: LineItemGroup;
        items: LineItem[];
        isRental: boolean;
    }>();

    const { t } = useI18n();
    const { relativeDateFormatter } = useDateFormatter();

    const { cart } = useCartQuery();
    const { forLineItemGroupId } = useCheckoutDeliveryOptionQuery();
    const checkoutDeliveryOption = computed(() => forLineItemGroupId(props.group.id).value);
    const depot = computed(() => props.items.map((item) => item.variant.depotQuantity.depot).find(isDefined));
    const depotId = computed(() => depot.value?.depot_id ?? '');
    const title = computed(() => depot.value?.address.city ?? t('CHECKOUT.SUMMARY.UNKNOWN_ADDRESS'));
    const isPickup = computed(() => props.group.transport_type === TransportType.PickUp);
    const deliveryOptionDisplay = computed(() => deliveryOptionDisplayFor(props.group.transport_type));
    const cartShippingAddress = computed(() => {
        const address = cart.value?.shipping_address;

        return address != null ? medusaAddressToAddressDto(address) : undefined;
    });
    const depotAddress = computed(() => {
        const address = props.group.depot.address;

        return address != null ? medusaAddressToAddressDto(address) : undefined;
    });

    const isProductionSite = computed(() => props.group.depot.type === DepotType.ProductionSite);

    const earliestPossibleDeliveryDate = computed(() => {
        const date = props.group.estimated_delivery_date_start;

        return date != null ? parseISO(date) : undefined;
    });

    const earliestPossibleDeliveryDateDisplay = computed(() => getDeliveryOptionString(checkoutDeliveryOption.value));

    function getDeliveryOptionString(checkoutDeliveryOptionType: CheckoutDeliveryOption): string {
        switch (checkoutDeliveryOptionType) {
            case CheckoutDeliveryOption.AsSoonAsPossible: {
                if (earliestPossibleDeliveryDate.value == null) {
                    return t('CART.STEPS.DELIVERY.DELIVERY_DATE_UNKNOWN');
                }

                const difference = differenceInWeeks(earliestPossibleDeliveryDate.value, Date.now());

                return relativeDateFormatter.formatWeeks(difference).value;
            }
            case CheckoutDeliveryOption.Custom: {
                const deliveryDateStart = props.group.estimated_delivery_date_start;
                const deliveryDateEnd = props.group.estimated_delivery_date_end;

                if (deliveryDateStart == null || deliveryDateEnd == null) {
                    return t('CART.STEPS.DELIVERY.DELIVERY_DATE_UNKNOWN');
                }

                const dateFormatter = useDateFormatter().dateFormatter;
                return `${dateFormatter.formatDateTwoDigits(parseISO(deliveryDateStart))} - ${dateFormatter.formatDateTwoDigits(parseISO(deliveryDateEnd))}`;
            }
        }
    }

    function deliveryOptionDisplayFor(transportType: TransportType): string {
        switch (transportType) {
            case TransportType.CraneTruck:
                return t('CART.STEPS.DELIVERY.METHOD.CRANE_TRUCK.TITLE');
            case TransportType.PickUp:
                return t('CART.STEPS.DELIVERY.METHOD.PICK_UP.TITLE');
            case TransportType.Truck:
                return t('CART.STEPS.DELIVERY.METHOD.TRUCK.TITLE');
        }
    }

    const filteredLineItems = computed(() =>
        lineItemsWithoutChildsSortedByDateAndContainersAndAdditionalProducts(props.items)
    );
</script>

<template>
    <Card>
        <template v-if="!isRental" #title>
            <h3 v-if="!isProductionSite" class="text-base-semibold-line-height-auto">
                {{ t('CHECKOUT.SUMMARY.DEPOT', { depot: title, id: depotId }) }}
            </h3>
            <h3 v-else class="text-base-semibold-line-height-auto">{{ t('CHECKOUT.SUMMARY.FROM_FACTORY') }}</h3>
        </template>
        <template #content>
            <div class="content">
                <div class="items">
                    <div v-for="item in filteredLineItems" :key="item.id" class="item">
                        <ProductImage
                            :url="item.thumbnail ?? undefined"
                            :alt="item.variant.product?.title ?? ''"
                            class="product-image"
                        />
                        <div>
                            <div class="item-title">{{ item.title }}</div>
                            <div class="item-quantity">
                                {{ t('CHECKOUT.SUMMARY.QUANTITY', { quantity: item.quantity }) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="delivery-option">
                        <span :class="isPickup ? 'pi pi-building' : 'pi pi-truck'" class="icon" />
                        <span>{{ deliveryOptionDisplay }}</span>
                    </div>
                    <div class="address-container">
                        <span class="pi pi-map-marker icon" />
                        <div v-if="cartShippingAddress != undefined && !isPickup">
                            <AddressDisplay :address="cartShippingAddress"></AddressDisplay>
                        </div>
                        <div v-else-if="depotAddress != undefined && isPickup">
                            <AddressDisplay :address="depotAddress"></AddressDisplay>
                        </div>
                    </div>
                    <div v-if="!isRental" class="delivery-date">
                        <span class="pi pi-calendar icon" />
                        <span> {{ earliestPossibleDeliveryDateDisplay }}</span>
                    </div>
                </div>
            </div>
        </template>
    </Card>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .content {
        display: flex;
        gap: main.$spacing-5;
        flex-wrap: wrap;

        > * {
            flex: 1;
        }
    }

    .items {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .item {
        display: flex;
        gap: 1ch;
    }

    .product-image {
        width: 48px;
        height: 48px;
        object-fit: cover;
    }

    .item-title {
        white-space: nowrap;
    }

    .item-quantity {
        white-space: nowrap;
        color: main.$textSecondaryColor;
    }

    .delivery-option {
        white-space: nowrap;
    }

    .delivery-date {
        white-space: nowrap;
        color: main.$color-green-dark;
    }

    .address-container {
        display: flex;
        white-space: nowrap;
    }

    .icon {
        margin-right: main.$spacing-3;
    }
</style>
