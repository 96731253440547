<script setup lang="ts">
    import { Navigation } from '@/router/navigation';
    import NavLogo from '@/components/navigation/NavLogo.vue';
    import AccountButton from '@/components/AccountButton.vue';
    import { RouterLink } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import router from '@/router';
    import { isMobile } from '@/util/breakpoints';
    import { getComputedCartUrlByProvisionType } from '@/util/router';
    import { computed } from 'vue';

    defineProps<{
        isRental: boolean;
    }>();

    const { t } = useI18n();

    const cartUrl = getComputedCartUrlByProvisionType();

    const accountMenuItems = computed(() => [
        {
            separator: true,
        },
        {
            label: t('CHECKOUT.BACK_TO_CART'),
            command: async () => {
                await router.push({ name: cartUrl.value });
            },
        },
        {
            separator: true,
        },
    ]);
</script>

<template>
    <div class="nav-bar">
        <div class="nav-bar-top max-width-container" :class="{ 'nav-bar-top--mobile': isMobile }">
            <div class="left-container">
                <RouterLink
                    class="logo-link"
                    :to="isRental ? { name: Navigation.RentalProductList } : { name: Navigation.SalesProductList }"
                >
                    <NavLogo :is-mobile="isMobile" />
                </RouterLink>
                <span class="text-base-regular-line-height-auto">{{ t('CART.CHECKOUT_TITLE') }}</span>
            </div>
            <AccountButton :menu-items="accountMenuItems" :show-drop-down-indicator="false" />
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .nav-bar {
        @include main.apply-scrollbar-spacing;

        background: main.$ctx-primary-color;
        color: main.$vt-c-white;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .logo-link {
        padding: main.$spacing-3 0;
        line-height: 1;
    }

    .nav-bar-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: main.$spacing-5;
        height: 56px;
    }

    .nav-bar-top--mobile {
        height: main.$spacing-height-nav-bar;
    }

    .back-to-cart-btn {
        color: main.$vt-c-white;
        box-shadow: none;

        &:hover {
            background-color: main.$primaryDarkColor;
        }
    }

    .left-container {
        display: flex;
        align-items: center;
        gap: main.$spacing-7;
    }
</style>
