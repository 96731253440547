<script setup lang="ts">
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { useI18n } from 'vue-i18n';

    defineProps<{
        price: number | null;
    }>();

    const { t } = useI18n();
    const { formatPrice } = usePriceFormatter();
</script>

<template>
    <span v-if="price == null">{{ t('CART.DELIVERY.COST.INQUIRY') }}</span>
    <span v-else-if="price === 0">{{ t('CART.DELIVERY.COST.FREE') }}</span>
    <span v-else class="price">{{ formatPrice(price ?? 0) }}</span>
</template>

<style scoped lang="scss">
    .price {
        white-space: nowrap;
    }
</style>
