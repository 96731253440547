<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { useProvisionTypeQuery } from '@/composables/provision-type';

    defineProps<{
        postalcode: string | undefined;
    }>();

    const { t } = useI18n();
    const { isRentalProvisionType } = useProvisionTypeQuery();
</script>

<template>
    <div>
        <div class="text-base-semibold-line-height-auto">
            {{ t('CHECKOUT.SHIPPING_ADDRESS_DETAIL.COMPLETE_ADDRESS') }}
        </div>

        <div v-if="postalcode != null">
            {{
                t(`CHECKOUT.SHIPPING_ADDRESS_DETAIL.${isRentalProvisionType ? 'POSTAL_CODE_RENTAL' : 'POSTAL_CODE'}`, {
                    postalCode: postalcode,
                })
            }}
        </div>
    </div>
</template>
