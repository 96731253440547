import { object, string, z } from 'zod';

export const CheckoutAddress = object({
    firstName: string(),
    lastName: string(),
    city: string(),
    postalCode: string(),
    addressLine1: string(),
    addressLine2: string().optional(),
    phone: string(),
    countryCode: string(),
});

export type CheckoutAddress = z.infer<typeof CheckoutAddress>;
