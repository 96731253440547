import { useProvisionTypeQuery } from '@/composables/provision-type';
import { stringValueOfParam } from '@/util/param';
import { computed, type Ref } from 'vue';
import { useRoute } from 'vue-router';

export function useCheckoutTosLink(): Ref<string> {
    const route = useRoute();
    const { isRentalProvisionType } = useProvisionTypeQuery();
    const selectedLanguage = computed(() => stringValueOfParam(route.params.language));

    const checkoutTosLink = computed(() =>
        isRentalProvisionType.value
            ? `https://catalog.containex.com/catalog/CONTAINEX/${selectedLanguage.value}/catalogs/Generelle_Mietbedingungen/pdf/Generelle_Mietbedingungen.pdf`
            : `https://catalog.containex.com/catalog/CONTAINEX/${selectedLanguage.value}/catalogs/Generelle_Lieferbedingungen/pdf/Generelle_Lieferbedingungen.pdf`
    );

    return checkoutTosLink;
}
